<form
  *ngIf="formInitialized"
  [formGroup]="form"
  (ngSubmit)="saveChanges()"
  class="op-form"
>
  <op-immediate-reminder-settings class="op-form--fieldset"></op-immediate-reminder-settings>
  <op-reminder-settings-daily-time class="op-form--fieldset"></op-reminder-settings-daily-time>
  <op-workdays-settings class="op-form--fieldset"></op-workdays-settings>
  <op-email-alerts-settings class="op-form--fieldset"></op-email-alerts-settings>

  <div class="op-form--submit m-0">
    <button
      class="button -primary custom-secondary-button rounded-border m-0 mr-2 px-3"
      [textContent]="text.save"
      type="submit"
    ></button>
  </div>
</form>
